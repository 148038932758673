/**
 * Ensures the given parameter is an invariant.
 * Useful to ensure we never reach the default clause in a switch case with an
 * enum.
 *
 * From https://github.com/gcanti/fp-ts
 */
export function absurd<A>(value: never): A {
  throw new Error(`Called \`absurd\` function which should be uncallable with value ${value}`);
}
